import React, { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalState } from "../../../atoms/atoms";
import DialogContentText from "@mui/material/DialogContentText";
import { currencies, shippingCarriers } from "../../../common";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Table from "../../../components/Table";
import DatePicker from "../../../components/DatePicker";
import dayjs from "dayjs";
import IconButton from "@mui/material/IconButton";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import BarcodeScanner from "./BarcodeScannerModal";

const ShipItemsContent = ({ data }) => {
  const state = useRecoilValue(modalState);
  const setState = useSetRecoilState(modalState);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [localSelection, setLocalSelection] = useState([]);
  const [timezone, setTimezone] = useState(
    data.items[0]?.warehouse?.slice(0, 2)
  );

  const [isScannerOpen, setIsScannerOpen] = useState(false);
  const [scanTarget, setScanTarget] = useState(null);

  useEffect(() => {
    let currency = currencies.find(
      (currency) =>
        currency.slice(0, 2) ===
        (data.selectedItem?.warehouse?.slice(0, 2) ||
          data.items[0]?.warehouse?.slice(0, 2))
    );
    setState({
      serialNumberItems: [],
      currency: currency || "USD",
      shippingCostItems: [],
      shippingCompany: data.items[0].shippingCompany,
      trackingNumber: data.items[0].trackingNumber,
      date: dayjs(),
      selectionModel: [],
    });
    setLocalSelection([]);

    if (data.selectedItem)
      handleSelectionChange(true, "selection", data.selectedItem.uid);
  }, [data]);

  useEffect(() => {
    setLocalSelection(state?.selectionModel || []);
  }, [state?.selectionModel]);

  const handleSelectionChange = useCallback(
    (isSelected, field, id) => {
      if (field !== "selection") return;

      const selectedItem = data.items.find((item) => item.id === id);
      if (!selectedItem) return;

      const updateSelectionModel = isSelected
        ? [id, ...(state.selectionModel || [])]
        : (state.selectionModel || []).filter((itemId) => itemId !== id);

      const newWarehouse = updateSelectionModel.length
        ? selectedItem.warehouse
        : "";
      setSelectedWarehouse(newWarehouse);

      // Set timezone based on the warehouse
      const newTimezone = newWarehouse.slice(0, 2);
      setTimezone(newTimezone === "FD" ? "US" : newTimezone || "AU");

      const updatedItems =
        updateSelectionModel.length > 0
          ? {
              serialNumberItems: data.serialNumberItems
                .filter((item) => updateSelectionModel.includes(item.id))
                .map((item) => ({
                  ...item,
                  serialNumber:
                    (state.serialNumberItems || []).find(
                      (si) => si.id === item.id
                    )?.serialNumber ?? item.serialNumber,
                })),
              shippingCostItems: data.items
                .filter((item) => updateSelectionModel.includes(item.id))
                .map((item) => ({
                  ...item,
                  shippingCost:
                    (state.shippingCostItems || []).find(
                      (si) => si.id === item.id
                    )?.shippingCost ?? item.shippingCost,
                })),
            }
          : { serialNumberItems: [], shippingCostItems: [] };

      const shippingCompany =
        selectedItem.shippingCompany ||
        (selectedItem.warehouse === "AUGC"
          ? "TNT-AU"
          : selectedItem.warehouse === "CAVC"
          ? "Acumen Logistics"
          : selectedItem.warehouse === "NZAKL"
          ? "TSS Sensitive Freight NZ"
          : selectedItem.warehouse === "UKDHL"
          ? "DHL"
          : "");

      setLocalSelection(updateSelectionModel);
      const selectedItems = updatedItems.shippingCostItems;

      setState((prevState) => ({
        ...prevState,
        ...updatedItems,
        selectionModel: updateSelectionModel,
        shippingCompany,
        trackingNumber:
          selectedItems?.length > 0 ? selectedItems[0].trackingNumber : "",
      }));
    },
    [
      data.items,
      data.serialNumberItems,
      state.serialNumberItems,
      state.shippingCostItems,
      state.selectionModel,
      setState,
    ]
  );

  const handleScanClick = (id) => {
    setScanTarget(id);
    setIsScannerOpen(true);
  };

  const handleScanComplete = (barcode) => {
    setState((prevState) => ({
      ...prevState,
      serialNumberItems: prevState.serialNumberItems.map((item) =>
        item.id === scanTarget ? { ...item, serialNumber: barcode } : item
      ),
    }));
    setIsScannerOpen(false);
  };

  const scheduleColumns = [
    {
      headerName: "",
      field: "selection",
      width: 70,
      editable: true,
      renderEditCell: (params) => {
        const isSelectable =
          !selectedWarehouse || params.row.warehouse === selectedWarehouse;
        const isChecked = localSelection.includes(params.row.id);
        return (
          <Checkbox
            checked={isChecked}
            onChange={(event) =>
              handleSelectionChange(
                event.target.checked,
                "selection",
                params.row.id
              )
            }
            disabled={!isSelectable}
          />
        );
      },
    },
    { field: "warehouse", headerName: "Warehouse", flex: 1 },
    { field: "id", headerName: "UID", flex: 3 },
    { field: "description", headerName: "Description", flex: 3 },
    { field: "qty", headerName: "Qty", flex: 1 },
    { field: "serialNumber", headerName: "Serial Number", flex: 2 },
    { field: "shippingStatus", headerName: "Status", flex: 2 },
  ];

  const serialNumbersColumns = [
    { field: "id", headerName: "UID", flex: 1 },
    {
      field: "serialNumber",
      headerName: "Serial Number",
      flex: 1,
      editable: true,
      type: "string",
      required: true,
      renderEditCell: (params) => (
        <Box display="flex" alignItems="center" gap={1}>
          <TextField
            fullWidth
            value={params.row.serialNumber || ""}
            onChange={(e) =>
              handleInputChange(e.target.value, "serialNumber", params.row.id)
            }
          />
          <IconButton
            onClick={() => handleScanClick(params.row.id)}
            color="primary"
          >
            <QrCodeScannerIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const shippingCostsColumns = [
    { field: "id", headerName: "UID", flex: 1 },
    {
      field: "shippingCost",
      headerName: "Shipping Cost",
      flex: 1,
      editable: true,
      type: "number",
    },
  ];

  const handleInputChange = (value, field, id) => {
    if (field === "selection") {
      handleSelectionChange(value, field, id);
    } else if (field === "serialNumber") {
      setState((prevState) => ({
        ...prevState,
        serialNumberItems: prevState.serialNumberItems.map((item) =>
          item.id === id ? { ...item, [field]: value } : item
        ),
      }));
    } else if (field === "shippingCost") {
      setState((prevState) => ({
        ...prevState,
        shippingCostItems: prevState.shippingCostItems.map((item) =>
          item.id === id ? { ...item, [field]: value } : item
        ),
      }));
    }
  };

  return (
    <Box>
      <DialogContentText>Which items would you like to ship?</DialogContentText>
      <Box width={"100%"} mt={2}>
        <Table
          key={localSelection.join(",")}
          columns={scheduleColumns}
          rows={data.items || []}
          onCellChange={handleInputChange}
        />
      </Box>
      {state?.serialNumberItems?.length !== 0 && (
        <>
          <Typography sx={{ mt: 4 }} fontWeight={"bold"}>
            Serial Numbers
          </Typography>
          <Box width={"100%"} mt={2}>
            <Table
              columns={serialNumbersColumns}
              rows={state?.serialNumberItems || []}
              onCellChange={handleInputChange}
            />
          </Box>
        </>
      )}
      {state?.shippingCostItems?.length !== 0 && (
        <>
          <Grid container alignItems="center" justifyContent="center" mt={2}>
            <Grid item xs={6}>
              <Typography fontWeight={"bold"}>Shipping Costs</Typography>
            </Grid>
            <Grid item xs={6} container justifyContent="flex-end">
              <Autocomplete
                size={"small"}
                value={state?.currency || null}
                onChange={(event, newValue) => {
                  setState({ ...state, currency: newValue });
                }}
                options={currencies}
                sx={{ mt: 2, mb: 2, width: 130 }}
                renderInput={(params) => (
                  <TextField {...params} label="Currency" required />
                )}
                isOptionEqualToValue={(option, value) =>
                  option === value ||
                  option.label === value ||
                  option === value.label
                }
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  return option.label || "";
                }}
              />
            </Grid>
            <Grid xs={12} item>
              <Table
                columns={shippingCostsColumns}
                rows={state?.shippingCostItems || []}
                onCellChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} mt={2}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                value={state?.shippingCompany || null}
                onChange={(event, newValue) => {
                  setState({ ...state, shippingCompany: newValue });
                }}
                options={shippingCarriers}
                sx={{ mb: 1, width: "100%" }}
                renderInput={(params) => (
                  <TextField {...params} label="Shipping Company" required />
                )}
                isOptionEqualToValue={(option, value) =>
                  option === value ||
                  option.label === value ||
                  option === value.label
                }
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  return option.label || "";
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ mb: 2, width: "100%" }}
                label={"Tracking Number"}
                value={state?.trackingNumber}
                onChange={(e) =>
                  setState({ ...state, trackingNumber: e.target.value })
                }
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePicker
                label={"Shipped Date"}
                value={state?.date}
                onChange={(newValue) =>
                  setState({ ...state, date: dayjs(newValue) })
                }
                startingTimezone={timezone}
                required
              />
            </Grid>
          </Grid>
        </>
      )}
      <BarcodeScanner
        open={isScannerOpen}
        handleClose={() => setIsScannerOpen(false)}
        onScan={handleScanComplete}
      />
    </Box>
  );
};

export default ShipItemsContent;
