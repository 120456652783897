import { useState } from "react";
import {
  Box,
  Chip,
  FormHelperText,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const SerialNumberInput = ({
  value = [],
  onChange,
  rowId,
  fieldName,
  maxQuantity,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [rangeStart, setRangeStart] = useState("");
  const [rangeEnd, setRangeEnd] = useState("");
  const [error, setError] = useState("");

  const isSerialInputValid = inputValue && !value.includes(inputValue);
  const isRangeInputValid =
    rangeStart &&
    rangeEnd &&
    parseInt(rangeStart, 10) <= parseInt(rangeEnd, 10);

  const isElevenDigits = (serial) => /^\d{11}$/.test(serial);
  const isSerialPatternOk = (serial) => serial.at(4) === "4" && +serial.at(5) > 4; // e.g. "xxxx45..."

  const getWarnings = (serial) => {
    const warnings = [];
    if (!isElevenDigits(serial)) {
      warnings.push("Serial numbers should be 11 digits long");
    }
    if (!isSerialPatternOk(serial)) {
      warnings.push("Serial numbers usually have a 'xxxx45xxxxx' pattern");
    }
    return warnings;
  };

  const countTotalSerialNumbers = (serialNumbers) => {
    return serialNumbers.reduce((total, serialNumber) => {
      if (serialNumber.includes("-")) {
        const [start, end] = serialNumber
          .split("-")
          .map((num) => parseInt(num, 10));
        return total + (end - start + 1);
      }
      return total + 1;
    }, 0);
  };

  const currentTotal = countTotalSerialNumbers(value);

  const isWithinLimit = (total) => {
    return maxQuantity ? total <= maxQuantity : true;
  };

  const handleAddSerialNumber = () => {
    if (value.includes(inputValue)) {
      setError("Serial number already exists");
      return;
    }

    const newTotal = countTotalSerialNumbers([...value, inputValue]);
    if (!isWithinLimit(newTotal)) {
      setError(`Cannot have more serials than Quantity Ordered (${maxQuantity})`);
      return;
    }

    if (isSerialInputValid) {
      const warnings = getWarnings(inputValue);
      if (warnings.length > 0) {
        setError(`Warning: ${warnings.join(" and ")}`);
      } else {
        setError("");
      }
      onChange([...value, inputValue], fieldName || "serialnumbers", rowId);
      setInputValue("");
    }
  };

  const handleAddRange = () => {
    if (!isRangeInputValid) {
      setError("Please enter a valid range");
      return;
    }

    const newStart = parseInt(rangeStart, 10);
    const newEnd = parseInt(rangeEnd, 10);

    const rangeSize = newEnd - newStart + 1;
    const newTotal = countTotalSerialNumbers(value) + rangeSize;

    if (!isWithinLimit(newTotal)) {
      setError(`Cannot have more serials than Quantity Ordered (${maxQuantity})`);
      return;
    }

    let isOverlapping = false;

    // Check each existing entry
    for (const entry of value) {
      if (entry.includes("-")) {
        // Check against existing range
        const [existingStart, existingEnd] = entry
          .split("-")
          .map((num) => parseInt(num, 10));
        if (
          (newStart >= existingStart && newStart <= existingEnd) ||
          (newEnd >= existingStart && newEnd <= existingEnd) ||
          (newStart <= existingStart && newEnd >= existingEnd)
        ) {
          isOverlapping = true;
          break;
        }
      } else {
        // Check against single serial number
        const serialNumber = parseInt(entry, 10);
        if (serialNumber >= newStart && serialNumber <= newEnd) {
          isOverlapping = true;
          break;
        }
      }
    }

    if (isOverlapping) {
      setError("Range overlaps with existing entries");
      return;
    }

    // Check both numbers for warnings before adding
    const startWarnings = getWarnings(rangeStart);
    const endWarnings = getWarnings(rangeEnd);

    if (startWarnings.length > 0 || endWarnings.length > 0) {
      const allWarnings = [...new Set([...startWarnings, ...endWarnings])];
      setError(`Warning: ${allWarnings.join(" and ")}`);
    } else {
      setError("");
    }

    // Add the range regardless of warnings
    const rangeEntry = `${rangeStart}-${rangeEnd}`;
    onChange([...value, rangeEntry], fieldName || "serialnumbers", rowId);
    setRangeStart("");
    setRangeEnd("");
  };

  const handleDelete = (entry) => {
    onChange(
      value.filter((item) => item !== entry),
      fieldName || "serialnumbers",
      rowId
    );
    setError("");
  };

  const renderChipLabel = (entry) => {
    if (entry.includes("-")) {
      const [start, end] = entry.split("-");
      return `${start} - ${end}`;
    }
    return entry;
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", gap: 1, width: "100%" }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ ml: 0.7 }} variant="caption" color="text.secondary">
          {maxQuantity
            ? `Serial Numbers: ${currentTotal} / ${maxQuantity}`
            : `Serial Numbers: ${currentTotal}`}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <TextField
          label="Serial Number"
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
            if (!error.startsWith("Warning")) {
              setError("");
            }
          }}
          size="small"
          sx={{ flexGrow: 1 }}
          error={
            error && !error.startsWith("Warning") && !rangeStart && !rangeEnd
          }
        />
        <Tooltip
          title={
            isSerialInputValid ? "Add Serial Number" : "Enter a serial number"
          }
        >
          <IconButton onClick={handleAddSerialNumber} size="small">
            <AddCircleIcon
              sx={{
                color: !inputValue
                  ? "grey.400"
                  : isSerialInputValid
                  ? "success.main"
                  : "grey.500",
              }}
            />
          </IconButton>
        </Tooltip>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1, mt: 1 }}>
        <TextField
          label="Range Start"
          value={rangeStart}
          onChange={(e) => {
            setRangeStart(e.target.value);
            if (!error.startsWith("Warning")) {
              setError("");
            }
          }}
          size="small"
          sx={{ width: "50%" }}
          error={
            error && !error.startsWith("Warning") && (rangeStart || rangeEnd)
          }
        />
        <TextField
          label="Range End"
          value={rangeEnd}
          onChange={(e) => {
            setRangeEnd(e.target.value);
            if (!error.startsWith("Warning")) {
              setError("");
            }
          }}
          size="small"
          sx={{ width: "50%" }}
          error={
            error && !error.startsWith("Warning") && (rangeStart || rangeEnd)
          }
        />
        <Tooltip title={isRangeInputValid ? "Add Range" : "Enter valid range"}>
          <IconButton onClick={handleAddRange} size="small">
            <AddCircleIcon
              sx={{
                color:
                  !rangeStart && !rangeEnd
                    ? "grey.400"
                    : isRangeInputValid
                    ? "success.main"
                    : "grey.500",
              }}
            />
          </IconButton>
        </Tooltip>
      </Box>
      {error && (
        <FormHelperText
          sx={{
            ml: 1,
            color: error.startsWith("Warning") ? "warning.main" : "error.main",
          }}
        >
          {error}
        </FormHelperText>
      )}
      <Box
        mt={1}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 0.5,
          maxHeight: "60px",
          overflowY: "auto",
        }}
      >
        {value?.map((entry) => (
          <Chip
            key={entry}
            label={renderChipLabel(entry)}
            onDelete={() => handleDelete(entry)}
            size="small"
          />
        ))}
      </Box>
    </Box>
  );
};

export default SerialNumberInput;
