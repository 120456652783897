import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalState } from "../../../atoms/atoms";
import { splitAndCapitalize } from "../../../utils/stringUtils";
import Modal from "../../../components/Modal";
import { submitActions } from "../modals/submitActions";
import { validateFields } from "../modals/validation";
import { getModalContent } from "../modals/modalContentMap";

const DynamicModal = ({ refetch, onClose, currentModal }) => {
  const [error, setError] = useState("");
  const state = useRecoilValue(modalState);
  const setState = useSetRecoilState(modalState);
  const [page, setPage] = useState(1);
  const [isNotBlank, setIsNotBlank] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setPage(1);
  }, [currentModal]);

  const handleOnClose = () => {
    setState({});
    onClose();
  };

  const handleActionSubmit = async () => {
    setError("");
    setLoading(true);
    const validationError = validateFields[currentModal?.type](state);

    if (validationError) {
      setError(validationError);
      setLoading(false);
    } else {
      try {
        const result = await submitActions[currentModal?.type](state);
        // Handle successful submission
        console.log("Action submitted successfully:", result);
        setLoading(false);
        setState({});
        refetch(result);
        // You might want to close the modal or show a success message here
        onClose();
      } catch (error) {
        setLoading(false);

        let message = error.response?.data?.message || ""
        console.error("Error submitting action:", message, error);
        setError(
          "An error occurred while submitting the action. " + message
        );
      }
    }
  };
  return (
    <Modal
      openModal={!!currentModal}
      handleCloseModal={handleOnClose}
      title={splitAndCapitalize(currentModal?.type || "")}
      errorMsg={error}
      handleSubmit={handleActionSubmit}
      loading={loading}
      maxWidth={
        currentModal?.type === "newInventoryOrder" && page === 2
          ? "xs"
          : ["newInventoryOrder", "addNotes"].includes(currentModal?.type) &&
            page === 1
          ? "sm"
          : "xl"
      }
    >
      <Box sx={{ mt: error ? 1 : 0 }}>
        {getModalContent(currentModal, page, isNotBlank)}
      </Box>
    </Modal>
  );
};

export default DynamicModal;
