import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import { realWarehouses } from "../../../common";
import TextField from "@mui/material/TextField";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalState } from "../../../atoms/atoms";
import { useEffect } from "react";
import DialogContentText from "@mui/material/DialogContentText";

const ChangeWarehouse = ({ data }) => {
  const state = useRecoilValue(modalState);
  const setState = useSetRecoilState(modalState);

  useEffect(() => {
    setState({ uid: data.order.uid, warehouse: data.order.warehouse });
  }, []);
  const handleChange = (newValue) => {
    setState({ ...state, warehouse: newValue });
  };

  return (
    <>
      <DialogContentText>
        Please select the new warehouse for the product from the dropdown below.
      </DialogContentText>
      <Box mt={3}>
        {state.warehouse && (
          <Autocomplete
            value={state.warehouse}
            onChange={(event, newValue) => {
              handleChange(newValue);
            }}
            options={realWarehouses}
            renderInput={(params) => (
              <TextField fullWidth required {...params} />
            )}
          />
        )}
      </Box>
    </>
  );
};
export default ChangeWarehouse;
