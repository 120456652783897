import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { Box } from "@mui/material";

import BuildInventoryOrderScreen from "./pages/BuildOrder";
import ReviewOrderScreen from "./pages/ReviewOrder";
import MainContainer from "../../layouts/main";
import Error from "../../components/Error";
import ColumnSelector from "../../components/TileView/ColumnSelector";

import {
  getInStockOrders,
  getInventoryOrderRecommendedQty,
  getInventoryOrders,
  performInventoryAction,
} from "../../api/InventoryOrders";
import { getProductsByCountryAndWarehouse } from "../../api/Products";
import { getFields } from "../../api/SearchFields";
import { buildQueryString } from "../../utils/filterUtils";

const BuildInventoryOrder = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const warehouseOrderNumber = searchParams.get("warehouseOrderNumber");
  const warehouse = searchParams.get("warehouse");

  const [currentScreen, setCurrentScreen] = useState("build");
  const [containerSize, setContainerSize] = useState(
    () => localStorage.getItem("lastSelectedContainerSize") || "40"
  );
  const [orderData, setOrderData] = useState({
    products: null,
    supplier: "",
    dateOrdered: dayjs(),
    notes: "",
    isAContainerOrder: localStorage.getItem("lastSelectedContainerSize")
      ? containerSize === "40" || containerSize === "20"
      : true,
  });

  const [selectedWarehouse, setSelectedWarehouse] = useState(() => {
    if (warehouse) return warehouse;
    const stored = localStorage.getItem("selectedWarehouse");
    return stored === "all" ? "AUGC" : stored || "AUGC";
  });
  const [warehouseProducts, setWarehouseProducts] = useState(null);
  const [error, setError] = useState("");
  const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(false);
  const [searchFields, setSearchFields] = useState([]);
  const [lockedProducts, setLockedProducts] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);

  const [originalProducts, setOriginalProducts] = useState(null);

  const fetchWarehouseData = useCallback(async () => {
    if (containerSize === "blank") return;

    try {
      const [inStockOrdersResponse, productDetailsResponse] = await Promise.all(
        [
          getInStockOrders(),
          getProductsByCountryAndWarehouse(
            selectedWarehouse.slice(0, 2),
            selectedWarehouse
          ),
        ]
      );

      const combinedProducts = combineProductData(
        inStockOrdersResponse.results,
        productDetailsResponse.countryProducts
      );

      setWarehouseProducts(
        combinedProducts.filter((prod) => prod.warehouse === selectedWarehouse)
      );
      setIsInitialDataLoaded(true);
    } catch (err) {
      console.error(err);
      setWarehouseProducts([]);
      setError(
        "We couldn't retrieve the product list for this warehouse. Please try again or select a different warehouse."
      );
      setIsInitialDataLoaded(true);
    }
  }, [selectedWarehouse, containerSize]);

  const fetchRecommendedQuantities = useCallback(
    async (isReset) => {
      try {
        const rsp = await getInventoryOrderRecommendedQty(
          selectedWarehouse,
          containerSize,
          isReset ? {} : lockedProducts
        );
        if (isReset) setLockedProducts({});
        updateOrderDataWithRecommendedQuantities(rsp);
      } catch (err) {
        console.error(err);
        setOrderData((prevState) => ({ ...prevState, products: [] }));
        setError(
          "We encountered an issue while calculating recommended quantities. Please try again later."
        );
      }
    },
    [warehouseProducts, selectedWarehouse, lockedProducts, containerSize]
  );

  const fetchOrders = async (filter) => {
    try {
      const [inventoryOrdersResponse, productDetailsResponse] =
        await Promise.all([
          getInventoryOrders(filter),
          getProductsByCountryAndWarehouse(
            selectedWarehouse.slice(0, 2),
            selectedWarehouse
          ),
        ]);

      const combinedProducts = combineProductData(
        inventoryOrdersResponse.results,
        productDetailsResponse.countryProducts
      );

      const processedProducts = combinedProducts.map((item) => ({
        ...item,
        qty: item.quantityordered,
        serialnumbers: item.serialnumbers
          ? item.serialnumbers.split("，")
          : null,
        deleteRow: false, // Initialize deleteRow field
      }));

      const dateOrderedObj = warehouseOrderNumber && inventoryOrdersResponse.results?.[0]?.dateordered
        ? {dateOrdered: inventoryOrdersResponse.results[0].dateordered}
        : {}

      setOrderData((prevState) => ({
        ...prevState,
        ...dateOrderedObj,
        products: processedProducts,
      }));

      setOriginalProducts(processedProducts);
    } catch (err) {
      console.error(err);
      setError("Failed to fetch orders. Please try again later.");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setCurrentScreen("build");
      setError("");
      if (
        selectedWarehouse &&
        containerSize !== "blank" &&
        !warehouseOrderNumber
      ) {
        setLockedProducts({});
        setWarehouseProducts(null);
        setOrderData((prevState) => ({ ...prevState, products: null }));
        setIsInitialDataLoaded(false);
        await fetchWarehouseData();
      } else if (warehouseOrderNumber) {
        await fetchWarehouseData();
        const filterDetails = {
          filters: [
            {
              searchTypes: [
                "Contains",
                "Does not contain",
                "Is one of",
                "Is not one of",
                "Is equal to",
                "Is not equal to",
                "Is blank",
                "Is not blank",
              ],
              fieldName: "warehouseordernumber",
              displayName: "Warehouse Order Number",
              values: [
                {
                  label: warehouseOrderNumber,
                  key: 2,
                },
              ],
              type: 2,
            },
          ],
        };
        const fetchQuery = buildQueryString("", filterDetails, "and");
        await fetchOrders(fetchQuery);
      }
    };

    fetchData();
  }, [
    selectedWarehouse,
    fetchWarehouseData,
    warehouseOrderNumber,
    containerSize,
  ]);

  useEffect(() => {
    if (isInitialDataLoaded && warehouseProducts && !warehouseOrderNumber) {
      fetchRecommendedQuantities();
    }
  }, [isInitialDataLoaded, warehouseProducts, warehouseOrderNumber]);

  useEffect(() => {
    getFields("InventoryInStock")
      .then((rsp) => {
        setSearchFields(
          rsp.searchFields.map((field, i) => ({
            ...field,
            label: field.displayName,
            key: i,
          }))
        );
      })
      .catch((err) => console.error("Failed to fetch search fields:", err));
  }, []);

  const combineProductData = (inStockProducts, detailedProducts) => {
    return inStockProducts.map((inStockProduct) => {
      const detailedProduct = detailedProducts?.find(
        (detailProduct) => detailProduct.pid === inStockProduct.pid
      );
      return { ...inStockProduct, ...detailedProduct };
    });
  };

  const updateOrderDataWithRecommendedQuantities = (recommendedQuantities) => {
    setOrderData((prevState) => {
      const updatedProducts = recommendedQuantities.map((prod) => {
        const warehouseProduct = warehouseProducts?.find(
          (wp) => wp.pid === prod.pid
        );
        return { ...warehouseProduct, ...prod };
      });

      setLockedProducts((prevLockedProducts) => {
        const updatedLockedProducts = { ...prevLockedProducts };
        Object.keys(updatedLockedProducts).forEach((pid) => {
          const updatedProduct = updatedProducts.find((p) => p.pid === pid);
          if (updatedProduct) {
            updatedLockedProducts[pid] = updatedProduct.qty;
          }
        });
        return updatedLockedProducts;
      });
      return {
        ...prevState,
        products: updatedProducts,
      };
    });
  };

  const handleCreateOrder = (data) => {
    setOrderData((prevState) => ({
      ...prevState,
      ...data,
      products: data.products.filter((prod) => prod.qty > 0),
    }));
    setCurrentScreen("review");
  };

  const handleChange = useCallback(
    (value, field, id) => {
      setOrderData((prevState) => {
        if (!id) {
          return { ...prevState, [field]: value };
        }

        return {
          ...prevState,
          products: prevState.products.map((prod) =>
            prod.id === id
              ? {
                  ...prod,
                  [field]: field === "qty" ? parseInt(value, 10) || 0 : value,
                }
              : prod
          ),
        };
      });

      if (lockedProducts.hasOwnProperty(id) && field === "qty") {
        setLockedProducts((prevState) => ({
          ...prevState,
          [id]: parseInt(value, 10) || 0,
        }));
      }
    },
    [lockedProducts]
  );

  const handleAddProduct = (newProduct) => {
    setOrderData((prevState) => {
      const existingProductIndex = prevState.products.findIndex(
        (product) => product.pid === newProduct.pid
      );

      if (existingProductIndex !== -1) {
        const updatedProducts = [...prevState.products];
        updatedProducts[existingProductIndex] = {
          ...updatedProducts[existingProductIndex],
          qty: updatedProducts[existingProductIndex].qty + 1,
        };
        return { ...prevState, products: updatedProducts };
      } else {
        return {
          ...prevState,
          products: [...prevState.products, { ...newProduct, qty: 1 }],
        };
      }
    });
  };

  const handleDeleteOrder = useCallback((product) => {
    setOrderData((prevState) => ({
      ...prevState,
      products: prevState.products.filter((prod) => prod.pid !== product.pid),
    }));

    setLockedProducts((prevState) => {
      const { [product.pid]: removed, ...rest } = prevState;
      return rest;
    });
  }, []);

  const handleWarehouseChange = (e) => {
    setSelectedWarehouse(e.target.value);
    localStorage.setItem("selectedWarehouse", e.target.value);
  };

  const handleLockToggle = (productId, isLocked) => {
    setLockedProducts((prevState) => {
      if (isLocked) {
        const product = orderData.products?.find(
          (prod) => prod.pid === productId
        );
        return { ...prevState, [productId]: product ? product.qty : 0 };
      } else {
        const { [productId]: removed, ...rest } = prevState;
        return rest;
      }
    });
  };

  const handleContainerSizeChange = (e) => {
    const newContainerSize = e.target.value;
    if (newContainerSize === "blank") {
      setOrderData((prevState) => ({ ...prevState, products: [] }));
      setLockedProducts({});
    } else {
      setOrderData((prevState) => ({
        ...prevState,
        isAContainerOrder: containerSize === "40" || containerSize === "20",
      }));
      setContainerSize(newContainerSize);
      localStorage.setItem("lastSelectedContainerSize", newContainerSize);
    }
  };

  const handleSubmitOrder = () => {
    setError("");

    // Validate products
    const validationError = orderData.products.some((item) => {
      if (!item.supplier?.trim()) {
        setError("Supplier is required for all items.");
        return true;
      }
      if (!+item.qty || item.qty < 0) { // todo allow audit correction negative qty, e.g. add (item.ordertype !== "Audit Correction" && item.qty < 0)
        // console.log("Error: item.qty:", item.qty, "\nitem:", item)
        setError("Quantity must be greater than 0 for all items, including " + item.pid);
        return true;
      }
      return false;
    });

    if (validationError) return;

    // Prepare payload based on whether it's an edit or new order
    const payload = warehouseOrderNumber
      ? {
          action: "EDIT_INVENTORY_ORDER",
          details: {
            isAContainerOrder: orderData.isAContainerOrder,
            orderSize: containerSize,
            warehouseOrderNumber,
            dateOrdered: orderData.dateOrdered,
            warehouse: selectedWarehouse,
            orderNotes: orderData.notes,
            items: [
              ...orderData.products,
              ...originalProducts
                .filter(
                  (origProd) =>
                    !orderData.products.some((prod) => prod.id === origProd.id)
                )
                .map((prod) => ({ ...prod, deleteRow: true })),
            ].map((prod) => ({
              rowNumber: prod.rownumber,
              inventoryItem: prod.inventoryitem,
              pid: prod.pid,
              quantityOrdered: prod.qty,
              tradeFinanceOrder: prod.tradefinanceorder,
              notes: prod.notes,
              deleteRow: prod.deleteRow || false,
              controlBoardPanelModel: prod.controlboardpanelmodel,
              serialNumbers: prod.serialnumbers,
              supplier: prod.supplier,
            })),
          },
        }
      : {
          action: "NEW_INVENTORY_ORDER",
          details: {
            orderSize: containerSize,
            isAContainerOrder: orderData.isAContainerOrder,
            dateOrdered: orderData.dateOrdered,
            warehouse: selectedWarehouse,
            orderNotes: orderData.notes,
            items: orderData.products.map((prod) => ({
              pid: prod.pid,
              inventoryItem: prod.inventoryitem,
              quantityOrdered: prod.qty,
              tradeFinanced: prod.tradefinanceorder || false,
              notes: prod.notes || "",
              supplier: prod.supplier,
              dateOrdered: prod.dateordered,
            })),
          },
        };

    setSubmitLoading(true);
    performInventoryAction({
      ...payload,
      details: {
        ...payload.details,
        isAContainerOrder: orderData.isAContainerOrder,
        orderSize: containerSize,
      },
    })
      .then(() => {
        navigate("/inventoryOrders/stockOrders");
      })
      .catch((err) => {
        console.error(err);
        setSubmitLoading(false);
        setError(
          "An error occurred while building the order. Please try again later."
        );
      });
  };

  const defaultSettings = {
    showHeaders: true,
    columnsDisplayed: searchFields.filter(
      (field) =>
        !field.isHiddenForTile &&
        field.fieldName !== "everywhere" &&
        field.dataType !== "image"
    ),
  };

  const hideFreightFiller =
    containerSize === "Express" || containerSize === "Ground";

  // Ensure we only make available the base set-of-1 product, e.g. single boxes of 10x mats, not a "set of 3 boxes of 10x mats" as that's only for website
  let warehouseBaseProducts = warehouseProducts?.filter(product => +product.pid.split("-")[1] === 1);

  let editTitle = currentScreen === "build"
    ? `Edit: ${warehouseOrderNumber}`
    : "Review and Confirm Edits"
  let newOrderTitle = currentScreen === "build"
    ? "Build Order"
    : "Review and Confirm New Order"

  return (
    <MainContainer
      title={warehouseOrderNumber ? editTitle : newOrderTitle}
    >
      {error && <Error errorMessage={error} sx={{ borderRadius: 0 }} />}

      {currentScreen === "build" ? (
        <>
          <Box
            sx={{
              position: "fixed",
              top: 5,
              right: { xs: 95, sm: 95, md: 105 },
              zIndex: 3000,
            }}
          >
            {searchFields && (
              <ColumnSelector
                page={"buildInventoryOrder"}
                searchFields={searchFields}
                defaultSettings={defaultSettings}
              />
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minHeight: "100vh",
              background: "white",
            }}
          >
            <BuildInventoryOrderScreen
              error={error}
              handleAddProduct={handleAddProduct}
              handleProductsChange={handleChange}
              handleDeleteOrder={handleDeleteOrder}
              onCreateOrder={handleCreateOrder}
              orderData={orderData}
              handleLockedPidChange={handleLockToggle}
              selectedWarehouse={selectedWarehouse}
              handleWarehouseChange={handleWarehouseChange}
              warehouseProducts={warehouseBaseProducts}
              containerSize={containerSize}
              handleContainerSizeChange={handleContainerSizeChange}
              fetchRecommendedQuantities={fetchRecommendedQuantities}
              lockedProducts={lockedProducts}
              isEditMode={!!warehouseOrderNumber}
              hideFreightFiller={hideFreightFiller}
            />
          </Box>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
            background: "white",
          }}
        >
          <ReviewOrderScreen
            orderData={orderData}
            onBack={() => setCurrentScreen("build")}
            onSubmit={handleSubmitOrder}
            handleProductsChange={handleChange}
            warehouseProducts={warehouseBaseProducts}
            loading={submitLoading}
            error={!!error}
            hideFreightFiller={hideFreightFiller}
          />
        </Box>
      )}
    </MainContainer>
  );
};

export default BuildInventoryOrder;
