import React, { useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useDateFormat } from "../../contexts/DateFormatContext";
import ClearIcon from "@mui/icons-material/Clear";

dayjs.extend(utc);
dayjs.extend(timezone);

const officeTimezoneMap = {
  AU: "Australia/Brisbane",
  CA: "America/Vancouver",
  NZ: "Pacific/Auckland",
  US: "America/Chicago",
  UK: "Europe/London",
};

const CustomDatePicker = ({
  label,
  value,
  onChange,
  clearable = false,
  minDate,
  timezone,
  startingTimezone = "AU",
  hideTimezoneSelector = false,
  required,
  disabled = false,
}) => {
  const { dateFormat } = useDateFormat();
  const [selectedTimezone, setSelectedTimezone] = useState(startingTimezone);

  const [dateValue, setDateValue] = useState(
    value ? dayjs.tz(value, officeTimezoneMap[selectedTimezone]) : null
  );

  useEffect(() => {
    if (value) {
      const newDate = dayjs(value);
      setDateValue(newDate.isValid() ? newDate : null);
    } else {
      setDateValue(null);
    }
  }, [value]);

  useEffect(() => {
    setSelectedTimezone(timezone || startingTimezone);
  }, [timezone, startingTimezone]);

  const handleDateChange = (newDate) => {
    onChange(newDate ? newDate : null);
  };

  const handleTimezoneChange = (event) => {
    const newTimezone = event.target.value;
    setSelectedTimezone(newTimezone);
    if (dateValue && dateValue.isValid()) {
      const newDate = dateValue.tz(officeTimezoneMap[newTimezone]);
      onChange(newDate);
    }
  };

  const clearDate = () => {
    onChange(null);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        gap: 1,
        maxWidth: hideTimezoneSelector ? 300 : 400,
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label={!!label ? `${label} ${required ? "*" : ""}` : ""}
          value={dateValue}
          onChange={handleDateChange}
          format={dateFormat}
          minDate={minDate}
          disabled={disabled}
          slotProps={{
            textField: {
              fullWidth: true,
              InputProps: {
                startAdornment: clearable ? (
                  <InputAdornment position="start">
                    <IconButton onClick={clearDate} size="small">
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              },
            },
          }}
        />
      </LocalizationProvider>

      {!hideTimezoneSelector && (
        <FormControl sx={{ width: 180 }}>
          <InputLabel id="timezone-select-label">Timezone</InputLabel>
          <Select
            label="Timezone"
            labelId="timezone-select-label"
            id="timezone-select"
            value={selectedTimezone}
            onChange={handleTimezoneChange}
            disabled={disabled}
          >
            {Object.keys(officeTimezoneMap).map((key) => (
              <MenuItem key={key} value={key}>
                {key}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Box>
  );
};

export default CustomDatePicker;
