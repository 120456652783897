import React, { useEffect, useState } from "react";
import { getFieldValues } from "../../api/SearchFields";
import { Board } from "./Board";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { cardViewSettingsState } from "../../atoms/atoms";
import { shippingStatuses } from "../../common";
import { getSettings } from "../../api/cardSettings";
import Loading from "../Loading";
import Box from "@mui/material/Box";


const predefinedInventoryStatusOrder = [
  "ORDERED",
  "IN PRODUCTION",
  "ON SHIP",
  "IN CUSTOMS",
  "INTERNAL TRANSIT",
  "ARRIVED AT WAREHOUSE",
  "RECEIVED",
];

const predefinedStatusCNOrder = [
  "保留装运 (Hold Shipment)",
  "未发货 (Not Shipped)",
  "预定的 (Scheduled)",
  "已出货 (Shipped)",
  "运输问题 (Shipping Problem)",
  "已交付 (Delivered)",
  "取消 (Cancelled)",
];

const getOrderedColumns = (fieldName, fieldValues, data) => {
  let predefinedOrder = [];

  // Determine which predefined order to use based on fieldName
  if (fieldName === "status") {
    predefinedOrder = predefinedInventoryStatusOrder;
  } else if (fieldName === "shippingstatus") {
    predefinedOrder = shippingStatuses;
  } else if (fieldName === "suppliershippingstatus") {
    predefinedOrder = predefinedStatusCNOrder;
  }

  if (predefinedOrder.length > 0) {
    // Create columns for all predefined values, even if they have no cards
    return predefinedOrder.map((value) => ({
      id: value,
      title: value,
      cards: data?.filter((order) => order[fieldName] === value) || [],
    }))
      .filter(column => {
        // Hide system columns that have 0 items
        let collapseIfEmptyColumnIds = [
          "SYS_ARRIVED_AT_CUSTOMS",
          "SYS_CUSTOMS_DELAY",
          "SYS_CUSTOMS_CLEARED",
          "SYS_TRANSIT_DELAY",
          "SYS_SHIPMENT_LOST",
          "ATTEMPTED_DELIVERY",
          "CANCELED"
        ];
        return !(column.cards?.length > 0 && collapseIfEmptyColumnIds.includes(column.id))
      });
  }

  // For non-predefined columns, use the existing logic
  return fieldValues.map((value) => ({
    id: value,
    title: value,
    cards: data?.filter((order) => order[fieldName] === value) || [],
  }));
};

const CardView = ({
  table,
  data,
  ordersLoading,
  searchFields,
  dataGridName,
  defaultColumnHeader,
}) => {
  const [boardData, setBoardData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [settingsLoaded, setSettingsLoaded] = useState(false);

  const settings = useRecoilValue(cardViewSettingsState);
  const setSettings = useSetRecoilState(cardViewSettingsState);

  useEffect(() => {
    setError(null);
    setLoading(true);
    setSettingsLoaded(false);
    const defaultSettings = {
      cardColumnHeaders: defaultColumnHeader,
      columnsDisplayed: searchFields.filter(
        (option) => !option.isHiddenForCard && option.fieldName !== "everywhere"
      ),
    };
    getSettings(dataGridName)
      .then((rsp) => {
        const userSettings = rsp.settings;

        const newSettings = {
          cardColumnHeaders:
            userSettings?.cardColumnHeaders ||
            defaultSettings.cardColumnHeaders,
          columnsDisplayed:
            userSettings?.columnsDisplayed || defaultSettings.columnsDisplayed,
          showColumnHeaders: userSettings?.showColumnHeaders ?? true,
          canMoveCards: userSettings?.canMoveCards ?? false,
        };

        setSettings(newSettings);
        setSettingsLoaded(true);
      })
      .catch((err) => {
        setLoading(false);
        const errorMessage =
          "Error: failed to load settings, please try again later. ";
        setError(errorMessage);
        setBoardData([]);
        console.error("Error fetching settings:", err);
      });
  }, [table, dataGridName]);

  useEffect(() => {
    if (!settingsLoaded) return;

    const fieldName = settings?.cardColumnHeaders?.fieldName;
    if (!fieldName) {
      setLoading(false);
      return;
    }

    getFieldValues(table, fieldName)
      .then((rsp) => {
        if (rsp.results.length === 1) {
          setLoading(false);
          setBoardData([]);
          return setError(
            "Error getting orders, please try again with a different column header. "
          );
        }
        setError(null);
        const fieldValues = rsp.results;
        const dataType = searchFields.filter(
          (field) => field.fieldName === fieldName
        )[0]?.dataType;

        // Use the new getOrderedColumns function
        const columns = getOrderedColumns(fieldName, fieldValues, data).map(
          (col) => ({
            ...col,
            dataType: dataType,
          })
        );

        // Filter out any columns with null/undefined IDs
        setBoardData(columns.filter((column) => column.id));
        setLoading(false);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
        console.log(err);
      });
  }, [settingsLoaded, settings, data, table, searchFields]);

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      {loading && (
        <Box width={"40px"} m={"auto"} mt={4}>
          <Loading />
        </Box>
      )}
      {boardData && !loading && !ordersLoading && (
        <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
          <Board
            table={table}
            error={error}
            searchFields={searchFields}
            columns={boardData}
          />
        </Box>
      )}
    </Box>
  );
};

export default CardView;
