export const validateFields = {
  newInventoryOrder: (details) => {
    if (!details.dateOrdered || !details.warehouse || !details.supplier) {
      return "Please fill in all required fields: Date Ordered, Warehouse, and Supplier.";
    }
    if (!details.rows || details.rows.length === 0) {
      return "Please add at least one item to the order.";
    }
    for (let item of details.rows) {
      if (!item.pid || !item.description || !item.qty) {
        return "Please fill in all required fields for each item: PID, Description, and Quantity.";
      }
    }
    return null;
  },

  createAuditCorrection: (details) => {
    if (!details.dateOrdered || !details.warehouse) {
      return "Please fill in all required fields: Date Ordered and Warehouse.";
    }
    if (!details.rows || details.rows.length === 0) {
      return "Please add at least one item to the correction.";
    }
    return null;
  },

  warehouseTransfer: (details) => {
    if (!details.dateOrdered || !details.warehouse || !details.supplier) {
      return "Please fill in all required fields: Date Ordered, Warehouse (to), and Supplier (from).";
    }
    if (!details.rows || details.rows.length === 0) {
      return "Please add at least one item to transfer.";
    }
    return null;
  },

  editInventoryOrder: () => null,

  shipInventory: () => null, // No additional validation needed as per the requirements

  receiveInventory: (details) => {
    if (!Array.isArray(details.rows)) {
      return "Invalid data structure for receiving inventory.";
    }
    return null;
  },

  updateStatus: (details) => {
    for (let item of details.rows) {
      if (!item.status) return "Please fill in all order statuses.";
    }
    return null;
  },

  addCostsByItem: (details) => {
    if (!Array.isArray(details.rows)) {
      return "Invalid data structure for adding costs by item.";
    }

    // First pass: Check which groups are used in any row
    const usedGroups = {
      customs: false,
      international: false,
      local: false,
      products: false,
      total: false,
    };

    details.rows.forEach((item) => {
      // Check if customs group is used
      if (item.customsfees || item.customscurrency || item.customsdatepaid) {
        usedGroups.customs = true;
      }

      // Check if international shipping group is used
      if (
        item.internationalshipmentcost ||
        item.intshipdatepaid
      ) {
        usedGroups.international = true;
      }

      // Check if local shipping group is used
      if (
        item.localshipmentcost ||
        item.currencylocalshipping ||
        item.locshipdatepaid
      ) {
        usedGroups.local = true;
      }

      if (item.totalproductcost) {
        usedGroups.products = true;
      }

      if (item.totalshippingcostusd) usedGroups.total = true;
    });

    // Second pass: Ensure each used group is completely filled in all rows
    for (let item of details.rows) {
      let hasAnyGroup = false;

      // If customs group is used anywhere, check it's complete in this row
      if (usedGroups.customs) {
        hasAnyGroup = true;
        if (
          !item.customsfees ||
          !item.customscurrency ||
          !item.customsdatepaid
        ) {
          return "If customs fields are used, they must be filled in for all items: Customs Fees, Currency, and Date Paid.";
        }
      }

      // If international shipping group is used anywhere, check it's complete in this row
      if (usedGroups.international) {
        hasAnyGroup = true;
        if (
          !item.internationalshipmentcost ||
          !item.currencyintshipping ||
          !item.intshipdatepaid
        ) {
          return "If international shipping fields are used, they must be filled in for all items: Cost, Currency, and Date Paid.";
        }
      }

      // If local shipping group is used anywhere, check it's complete in this row
      if (usedGroups.local) {
        hasAnyGroup = true;
        if (
          !item.localshipmentcost ||
          !item.currencylocalshipping ||
          !item.locshipdatepaid
        ) {
          return "If local shipping fields are used, they must be filled in for all items: Cost, Currency, and Date Paid.";
        }
      }

      if (usedGroups.products) {
        hasAnyGroup = true;
        if (!item.currencyproduct || !item.totalproductcost) {
          return "If product fields are used, they must be filled in for all items: Currency and Cost .";
        }
      }

      if (usedGroups.total) hasAnyGroup = true;

      // Check that at least one group is being used
      if (!hasAnyGroup) {
        return "Please fill in at least one group of fields (Customs, International Shipping, or Local Shipping).";
      }
    }

    return null;
  },
  distributeOrderCosts: (details) => {
    let hasFilledGroup = false;
    const data = details.rows[0]; // Customs group
    if (data.customsfees || data.customscurrency || data.customsdatepaid) {
      if (!data.customsfees || !data.customscurrency || !data.customsdatepaid) {
        return "Please fill in all customs-related fields: Customs Fees, Customs Currency, and Customs Date Paid.";
      }
      hasFilledGroup = true;
    }

    // International shipping group
    if (
      data.internationalshipmentcost ||
      data.currencyintshipping ||
      data.intshipdatepaid
    ) {
      if (
        !data.internationalshipmentcost ||
        !data.currencyintshipping ||
        !data.intshipdatepaid
      ) {
        return "Please fill in all international shipping fields: International Shipment Cost, Currency, and Date Paid.";
      }
      hasFilledGroup = true;
    }

    // Local shipping group
    if (
      data.localshipmentcost ||
      data.currencylocalshipping ||
      data.locshipdatepaid
    ) {
      if (
        !data.localshipmentcost ||
        !data.currencylocalshipping ||
        !data.locshipdatepaid
      ) {
        return "Please fill in all local shipping fields: Local Shipment Cost, Currency, and Date Paid.";
      }
      hasFilledGroup = true;
    }

    if (!hasFilledGroup) {
      return "Please fill in at least one group of fields (Customs, International Shipping, or Local Shipping).";
    }

    return null;
  },

  markAsPaid: (details) => {
    if (!Array.isArray(details.rows)) {
      return "Invalid data structure for marking as paid.";
    }
    for (let item of details.rows) {
      if (item.paid === undefined) {
        return "Please specify the Paid status for each item.";
      }
    }
    return null;
  },

  addMachineSpecs: () => null, // No additional validation needed as per the requirements
  addNotes: () => null,
};
