import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import { activeShippingStatuses } from "../../../common";
import TextField from "@mui/material/TextField";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalState } from "../../../atoms/atoms";
import { useEffect, useState } from "react";
import DialogContentText from "@mui/material/DialogContentText";
import DatePicker from "../../../components/DatePicker";
import dayjs from "dayjs";

const UpdateStatus = ({ data }) => {
  const state = useRecoilValue(modalState);
  const setState = useSetRecoilState(modalState);

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    setState({
      uid: data.order.uid,
      status: data.order.shippingstatus,
      deliveredDate: !!data.order.delivereddate
        ? dayjs(data.order.delivereddate)
        : dayjs(),
      shippedDate: !!data.order.shippeddate
        ? dayjs(data.order.shippeddate)
        : dayjs(),
    });
    setIsReady(true);
  }, []);
  const handleChange = (newValue) => {
    setState({ ...state, status: newValue });
  };

  return (
    <>
      <DialogContentText>
        Please select the new shipping status for the product from the dropdown
        below.
      </DialogContentText>
      <Box mt={3}>
        {isReady && (
          <Autocomplete
            value={state.status}
            onChange={(event, newValue) => {
              handleChange(newValue);
            }}
            options={activeShippingStatuses}
            renderInput={(params) => (
              <TextField required fullWidth {...params} />
            )}
          />
        )}
      </Box>
      {(state.status === "SHIPPED" ||
        (state.status === "DELIVERED" && !data.shippedDate)) && (
        <Box mt={3}>
          <DatePicker
            label={"Shipped Date"}
            value={state.shippedDate}
            onChange={(newValue) =>
              setState({ ...state, shippedDate: dayjs(newValue) })
            }
            startingTimezone={data.order.ordercountrycode}
            required
          />
        </Box>
      )}
      {state.status === "DELIVERED" && (
        <Box mt={3}>
          <DatePicker
            label={"Delivered Date"}
            value={state.deliveredDate}
            onChange={(newValue) =>
              setState({ ...state, deliveredDate: dayjs(newValue) })
            }
            startingTimezone={data.order.ordercountrycode}
            required
          />
        </Box>
      )}
    </>
  );
};
export default UpdateStatus;
