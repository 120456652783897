import React from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { inventoryWarehouses } from "../../../common";
import { Box } from "@mui/material";
import { getImage } from "../../../components/Datagrid/utils";
import InputLabel from "@mui/material/InputLabel";

const WarehouseSelect = ({
  warehouses,
  selectedWarehouse,
  handleWarehouseChange,
  hideLabel,
  disabled = false,
}) => {
  const image =
    selectedWarehouse !== "all"
      ? getImage(
          `Flag-${selectedWarehouse.slice(0, 2)}-to-${selectedWarehouse.slice(
            0,
            2
          )}.webp`
        )
      : null;

  return (
    <Box display={"flex"}>
      {selectedWarehouse !== "all" && (
        <img
          id={"card-text"}
          style={{ height: "40px", marginRight: "4px" }}
          src={image}
          alt={image}
          loading="lazy"
        />
      )}
      <FormControl
        variant="outlined"
        color="primary"
        size="small"
        fullWidth
        disabled={disabled}
        sx={{ minWidth: 120 }}
      >
        {!hideLabel && <InputLabel>Warehouse</InputLabel>}
        <Select
          value={selectedWarehouse}
          onChange={handleWarehouseChange}
          label={hideLabel ? "" : "Warehouse"}
        >
          {!warehouses && (
            <MenuItem value="all">
              <em>All Warehouses</em>
            </MenuItem>
          )}
          {(warehouses || inventoryWarehouses).map((warehouse) => (
            <MenuItem key={warehouse} value={warehouse}>
              {warehouse}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default WarehouseSelect;
